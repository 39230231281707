import React from "react";
import MobileStoreButton from "react-mobile-store-button";
import ReactStoreBadges from "react-store-badges";
import "../assets/css/index.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import brandlogo from "../assets/images/HomeImages/sbrandlogo.png";
import img1 from "../assets/images/HomeImages/img1.png";
import Breakpoints from "./Breakpoints";
import img2 from "../assets/images/HomeImages/img2.png";
import beezbag_logo from "../assets/images/HomeImages/beezbag.png";
import beezfood from "../assets/images/HomeImages/beezfood.png";
import img3 from "../assets/images/HomeImages/img3.png";
import img4 from "../assets/images/HomeImages/img4.png";
import img5 from "../assets/images/HomeImages/img5.png";
import home from "../assets/images/HomeImages/home.png";
import driver from "../assets/images/HomeImages/driver.png";
import user from "../assets/images/HomeImages/user.png";

import canadaflag from "../assets/images/HomeImages/candaflag.png";
import indiaflag from "../assets/images/HomeImages/indiaflag.png";

import insta from "../assets/images/HomeImages/insta.png";
import fb from "../assets/images/HomeImages/fb.png";
import twitter from "../assets/images/HomeImages/twitter.png";
import youtube from "../assets/images/HomeImages/youtube.png";
import mobilescreen from "../assets/images/HomeImages/mobilescreen.png";

import mobilescreen2 from "../assets/images/HomeImages/mobilescreen2.png";
import apple from "../assets/images/HomeImages/apple.png";
import aboutimg1 from "../assets/images/HomeImages/aboutimg1.png";
import aboutimg2 from "../assets/images/HomeImages/aboutimg2.png";
import playstore from "../assets/images/HomeImages/playstore.png";
import canada from "../assets/images/HomeImages/CanadaMapFlag.svg";
import circle from "../assets/images/HomeImages/circle.png";
import beezpaybox from "../assets/images/HomeImages/beezpay.png";
import beezchatbox from "../assets/images/HomeImages/beezchat.png";
import buisnessbox from "../assets/images/HomeImages/buisnessbox.png";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const IndexPage = () => {
  return (
    <>
      <section style={{ height: "100vh" }}>
        <div className="mt-2" style={{ height: "100px" }}>
          <Navbar expand="lg" sticky="top" className="col-md-11 col-11 mx-auto">
            <Navbar.Brand href="#home">
              <img src={brandlogo} style={{ height: "80px", width: "250px" }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto col-md-8 col-12 mx-auto d-flex justify-space-around  ">
                <Nav.Link
                  className="col-md-4"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "28px",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#555157",
                  }}
                  href="/"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  className="col-md-4"
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "28px",
                    fontWeight: "600",
                    color: "#555157",
                  }}
                  href="#about-us"
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  className="col-md-4"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "28px",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#555157",
                  }}
                  href="https://beezhive.medium.com/"
                  target="_blank"
                >
                  Blog
                </Nav.Link>
              </Nav>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="px-4"
                  style={{
                    borderRadius: "40px",
                    border: "none",
                    boxShadow: "1px 2px #707070",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    backgroundColor: "#FADE31",
                    color: "#231F20",
                  }}
                >
                  Download App
                </Button>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div
          className="row gx-0 mt-4  w-100"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <div
            className="row gx-0 col-md-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <img
              src={img3}
              className=""
              style={{ height: "250px", width: "70%" }}
            />
            <img
              src={img1}
              className=""
              style={{ height: "300px", width: "70%" }}
            />
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="row text-center">
              <span
                style={{
                  color: "#C2C2C2",
                  fontWeight: "500",
                  fontSize: "32px",
                }}
              >
                Meet
              </span>
              <span
                style={{
                  color: "#555157",
                  fontWeight: "500",
                  fontSize: "100px",
                }}
              >
                Beezhive
              </span>
              <span
                style={{
                  color: "#C2C2C2",
                  fontWeight: "400",
                  fontSize: "32px",
                }}
              >
                North America’s Super App
              </span>
              <div
                className="col-md-10 mt-4 mx-auto"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Button
                  className="px-4"
                  style={{
                    borderRadius: "40px",
                    fontWeight: "500",
                    border: "none",
                    boxShadow: "1px 2px #707070",
                    fontFamily: "Montserrat",
                    backgroundColor: "#FADE31",
                    color: "#231F20",
                    fontSize: "28px",
                  }}
                >
                  <img
                    className="my-auto"
                    src={playstore}
                    style={{ height: "30px", width: "30px" }}
                  ></img>{" "}
                  Play store
                </Button>
                <Button
                  className="px-4 my-auto"
                  style={{
                    borderRadius: "40px",
                    border: "none",
                    boxShadow: "1px 2px #707070",
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    backgroundColor: "#FADE31",
                    color: "#231F20",
                    fontSize: "28px",
                  }}
                >
                  <img
                    className=""
                    src={apple}
                    style={{
                      height: "35px",
                      width: "30px",
                      paddingBottom: "2%",
                    }}
                  ></img>{" "}
                  Apple store
                </Button>
              </div>
            </div>
          </div>
          <div
            className="row gx-0 col-md-3 "
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <img
              src={img4}
              className=""
              style={{ height: "300px", width: "80%", marginLeft: "auto" }}
            />
            <img
              src={img2}
              className=""
              style={{ height: "300px", width: "80%", marginLeft: "auto" }}
            />
          </div>
        </div>
      </section>
      <section
        id="about-us"
        className=""
        style={{ marginTop: "5rem", overflowX: "hidden" }}
      >
        <div className="row text-center">
          {" "}
          <span
            style={{
              color: "#555157",
              fontWeight: "500",
              fontSize: "80px",
            }}
          >
            We are Beezhive
          </span>
          <span
            style={{
              color: "#C2C2C2",
              fontWeight: "400",
              fontSize: "32px",
            }}
          >
            North America’s Only Super App
          </span>
        </div>

        <div
          className="row gx-0"
          style={{
            backgroundColor: "white",
            marginTop: "5rem",
            paddingTop: "5rem",
            paddingBottom: "2rem",

            borderTopLeftRadius: "12%",
            borderTopRightRadius: "12%",
          }}
        >
          <div className="row gx-0 col-md-6">
            <div style={{ paddingLeft: "5rem" }}>
              {" "}
              <span
                style={{
                  color: "#373139",
                  fontWeight: "400",
                  fontSize: "70px",
                  lineHeight: "4rem",
                }}
              >
                One App <br />
                That can do <br /> All Your jobs!
              </span>
            </div>
            <div style={{ paddingLeft: "5rem" }}>
              <span
                style={{
                  color: "#C2C2C2",
                  fontWeight: "400",
                  fontSize: "40px",
                  lineHeight: "3rem",
                }}
              >
                -Make Payments
                <br />
                -Chat with friends
                <br />
                -Order Food
                <br />
                -Order Grocery
                <br />
                -Book a Cab{" "}
              </span>
            </div>
          </div>
          <div
            className="row gx-0 col-md-6"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              className="w-100"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={aboutimg2}
                style={{
                  textAlign: "right",
                  height: "100px",
                  width: "100px",
                }}
              />
            </div>
            <img
              src={aboutimg1}
              style={{ height: "550px", width: "650px" }}
              className=""
            />
          </div>
        </div>
      </section>

      <section style={{ overflowX: "hidden", paddingBottom: "5rem" }}>
        <div
          className="row gx-0"
          style={{
            marginTop: "4rem",
            paddingTop: "5rem",
            paddingBottom: "2rem",
          }}
        >
          <div className="row gx-0 col-md-7">
            <div style={{ paddingLeft: "4rem" }}>
              {" "}
              <span
                style={{
                  color: "#555157",
                  fontWeight: "500",
                  fontSize: "80px",
                  lineHeight: "4rem",
                }}
              >
                An app
                <br />
                That can run nation
              </span>
            </div>
            <div style={{ paddingLeft: "4rem", paddingTop: "2rem" }}>
              <span
                style={{
                  color: "#A8A7A9",
                  fontWeight: "400",
                  fontSize: "40px",
                  lineHeight: "3rem",
                }}
              >
                North America’s Only Super App
              </span>
            </div>
            <div style={{ paddingLeft: "5rem", paddingTop: "2rem" }}>
              <Button
                className="px-4"
                style={{
                  borderRadius: "40px",
                  fontWeight: "600",
                  width: "fit-content",
                  border: "none",
                  boxShadow: "1px 2px #707070",
                  fontFamily: "Montserrat",
                  backgroundColor: "#FADE31",
                  color: "#231F20",
                }}
              >
                Explore More
              </Button>
            </div>
          </div>
          <div
            className="row gx-0 col-md-5"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <img
              src={canada}
              style={{ height: "300px", width: "550px" }}
              className=""
            />
          </div>
        </div>
      </section>
      <section style={{}}>
        <div
          className="row gx-0"
          style={{
            backgroundColor: "white",

            paddingTop: "5rem",
            paddingBottom: "6rem",
            borderTopLeftRadius: "5%",
            borderTopRightRadius: "5%",
          }}
        >
          <ul class="navsection">
            <li>
              <a href="#payments">Payments</a>
            </li>
            <li>
              <a href="#social">Social</a>
            </li>
            <li>
              <a href="#food">Food & Shoping</a>
            </li>
            <li>
              <a href="#buisness">Buisness</a>
            </li>
            <li>
              <a href="#transport">Transportation</a>
            </li>
          </ul>
        </div>
      </section>
      <section
        id="payments"
        className="payments"
        style={{
          backgroundColor: "#5FCD6C",

          paddingTop: "5rem",
          paddingBottom: "5rem",
          paddingLeft: "5%",
          borderTopLeftRadius: "5%",
          borderTopRightRadius: "5%",
        }}
      >
        <div className="row gx-0">
          <div className="col-md-7">
            <div className="payments-container  col-md-8">
              {" "}
              <img src={circle} style={{ height: "200px", width: "200px" }} />
              <div
                className="centered"
                style={{ fontSize: "70px", fontWeight: "700" }}
              >
                Payments
              </div>
            </div>
            <div>
              <span
                style={{
                  fontSize: "50px",
                  color: "white",
                  fontWeight: "400",
                  paddingLeft: "1rem",
                }}
              >
                transactions reliable and cashless.
              </span>
            </div>
          </div>
          <div className="col-md-5" style={{ paddingBottom: "2rem" }}>
            <img src={img4} style={{ height: "400px", width: "500px" }} />
          </div>
        </div>
        <div className="row gx-0">
          <div className="col-md-5 " style={{ textAlign: "center" }}>
            <img src={beezpaybox} style={{ height: "400px", width: "400px" }} />
          </div>
          <div
            className="col-md-7"
            style={{
              paddingLeft: "5%",
              paddingTop: "5%",
            }}
          >
            <span
              style={{ color: "white", fontSize: "42px", fontWeight: "500" }}
            >
              E-wallet, go cashless.
              <br /> Mobile payments made easy
            </span>
          </div>
        </div>
      </section>

      <section
        id="social"
        className="social"
        style={{
          backgroundColor: "#408BF9",

          paddingTop: "5rem",
          paddingBottom: "5rem",
          paddingLeft: "5%",
          borderTopLeftRadius: "5%",
          borderTopRightRadius: "5%",
        }}
      >
        <div className="row gx-0">
          <div className="col-md-7">
            <div className="social-container  col-md-8">
              {" "}
              <img src={circle} style={{ height: "200px", width: "200px" }} />
              <div
                className="centered-social"
                style={{ fontSize: "70px", fontWeight: "700" }}
              >
                Social
              </div>
            </div>
            <div>
              <span
                style={{
                  fontSize: "50px",
                  color: "white",
                  fontWeight: "400",
                  paddingLeft: "1rem",
                }}
              >
                We care about your privacy.
              </span>
            </div>
          </div>
          <div className="col-md-5" style={{ paddingBottom: "2rem" }}>
            <img src={img3} style={{ height: "400px", width: "500px" }} />
          </div>
        </div>
        <div className="row gx-0">
          <div className="col-md-5 " style={{ textAlign: "center" }}>
            <img
              src={beezchatbox}
              style={{ height: "400px", width: "400px" }}
            />
          </div>
          <div
            className="col-md-7"
            style={{
              paddingLeft: "5%",
              paddingTop: "5%",
            }}
          >
            <span
              style={{ color: "white", fontSize: "42px", fontWeight: "500" }}
            >
              Say “hello” to a beezchat <br />
              simple, powerful, and secure <br />
              messenger
            </span>
          </div>
        </div>
      </section>

      <section
        id="food"
        className="food"
        style={{
          backgroundColor: "#F2AD73",

          paddingTop: "5rem",
          paddingBottom: "5rem",
          paddingLeft: "5%",
          borderTopLeftRadius: "5%",
          borderTopRightRadius: "5%",
        }}
      >
        <div className="row gx-0">
          <div className="col-md-8">
            <div className="food-container  col-md-8">
              {" "}
              <img src={circle} style={{ height: "200px", width: "200px" }} />
              <div
                className="centered-food"
                style={{ fontSize: "70px", fontWeight: "700" }}
              >
                Food & Shopping
              </div>
            </div>
            <div>
              <span
                style={{
                  fontSize: "50px",
                  color: "white",
                  fontWeight: "400",
                  paddingLeft: "1rem",
                }}
              >
                Unmatched ordering experience.
              </span>
            </div>
          </div>
          <div className="col-md-4" style={{ paddingBottom: "2rem" }}>
            <img src={img1} style={{ height: "400px", width: "500px" }} />
          </div>
        </div>
        <div
          className="col-11 mx-auto"
          style={{
            flexwrap: "wrap",
          }}
        >
          <Carousel
            responsive={Breakpoints.responsive2}
            infinite={true}
            draggable={true}
            swipeable={true}
            autoPlay={true}
            arrows={false}
            containerClass=""
          >
            <div className="mx-2 rounded d-flex flex-column justify-content-center align-items-center">
              <div style={{ height: "300px" }}>
                <img
                  src={beezbag_logo}
                  width="100%"
                  className="rounded h-100"
                  alt=""
                />
              </div>
            </div>
            <span
              style={{ color: "white", fontSize: "42px", fontWeight: "500" }}
            >
              Enjoy the convenience of online grocery shopping
              and have your groceries delivered right to your home. <br />
            </span>
            <div className="mx-2 rounded d-flex flex-column justify-content-center align-items-center">
              <div style={{ height: "300px" }}>
                <img
                  src={beezfood}
                  width="100%"
                  className="rounded h-100"
                  alt=""
                />
              </div>
            </div>
            <span
              style={{ color: "white", fontSize: "42px", fontWeight: "500" }}
            >
              Order food from any restaurant online and have it delivered to your door in minutes. <br />
            </span>
          </Carousel>
        </div>
      </section>
      <section
        id="buisness"
        className="buisness"
        style={{
          backgroundColor: "#8CD6C4",

          paddingTop: "5rem",
          paddingBottom: "5rem",
          paddingLeft: "5%",
          borderTopLeftRadius: "5%",
          borderTopRightRadius: "5%",
        }}
      >
        <div className="row gx-0">
          <div className="col-md-8">
            <div className="buisness-container  col-md-8">
              {" "}
              <img src={circle} style={{ height: "200px", width: "200px" }} />
              <div
                className="centered-buisness"
                style={{ fontSize: "70px", fontWeight: "700" }}
              >
                Buisness
              </div>
            </div>
            <div>
              <span
                style={{
                  fontSize: "50px",
                  color: "white",
                  fontWeight: "400",
                  paddingLeft: "1rem",
                }}
              >
                Unlock growth of business.
              </span>
            </div>
          </div>
          <div className="col-md-4" style={{ paddingBottom: "2rem" }}>
            <img src={img5} style={{ height: "400px", width: "500px" }} />
          </div>
        </div>
        <div className="row gx-0">
          <div className="col-md-5 " style={{ textAlign: "center" }}>
            <img
              src={buisnessbox}
              style={{ height: "400px", width: "400px" }}
            />
          </div>
          <div
            className="col-md-7"
            style={{
              paddingLeft: "5%",
              paddingTop: "5%",
            }}
          >
            <span
              style={{ color: "white", fontSize: "42px", fontWeight: "500" }}
            >
              Enable contactless ordering & <br />
              payment in your store with <br />
              One app.
            </span>
          </div>
        </div>
      </section>
      <section
        id="transport"
        className="transport"
        style={{
          backgroundColor: "#0094F2",

          paddingTop: "5rem",
          paddingBottom: "5rem",
          paddingLeft: "5%",
          borderTopLeftRadius: "5%",
          borderTopRightRadius: "5%",
        }}
      >
        <div className="row gx-0">
          <div className="col-md-8">
            <div className="transport-container  col-md-8">
              {" "}
              <img src={circle} style={{ height: "200px", width: "200px" }} />
              <div
                className="centered-transport"
                style={{ fontSize: "70px", fontWeight: "700" }}
              >
                Transportation
              </div>
            </div>
            <div>
              <span
                style={{
                  fontSize: "50px",
                  color: "white",
                  fontWeight: "400",
                  paddingLeft: "1rem",
                }}
              >
                Move faster & freely with ease.
              </span>
            </div>
          </div>
          <div className="col-md-4" style={{ paddingBottom: "2rem" }}>
            <img src={img2} style={{ height: "400px", width: "500px" }} />
          </div>
        </div>
        <div className="row gx-0">
          <div className="col-md-5 " style={{ textAlign: "center" }}>
            <img
              src={buisnessbox}
              style={{ height: "400px", width: "400px" }}
            />
          </div>
          <div
            className="col-md-7"
            style={{
              paddingLeft: "5%",
              paddingTop: "5%",
            }}
          >
            <span
              style={{ color: "white", fontSize: "42px", fontWeight: "500" }}
            >
              Book cab or send anything <br /> across the city faster on single
              tap
              <br />
              With Beezride & Beezsend
            </span>
          </div>
        </div>
      </section>
      <section
        id="joinsection"
        className="joinsection"
        style={{ marginTop: "5rem", marginBottom: "5rem" }}
      >
        <div
          className="row gx-0"
          style={{
            marginLeft: "8%",
            color: "#666666",
            fontWeight: "600",
            fontSize: "80px",
          }}
        >
          Become part of Beezhive
        </div>

        <div className="row container gx-0 mx-auto">
          <div
            className="col-lg-4 px-4 col-md-6 col-12 "
            style={{ height: "550px" }}
          >
            <div className="hw-webi-work my-4 d-flex flex-column justify-content-center align-items-center h-100">
              <div
                id="prog"
                className="rounded-circle hw-work-1 mb-2 d-flex justify-content-center align-items-center"
              >
                <img src={home} alt="" height="50%" width="50%" />
              </div>
              <p className="  mb-2 text-center" style={{ fontSize: "48px" }}>
                Buisness
              </p>
              <p
                className="  mb-2 text-center px-4"
                style={{ fontSize: "24px", color: "#666666" }}
              >
                We empower merchants with cutting-edge technologies that help
                them multiply reach and sales.
              </p>
              <Button
                className="px-4"
                style={{
                  borderRadius: "40px",
                  border: "none",
                  boxShadow: "1px 2px #707070",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  backgroundColor: "#FADE31",
                  color: "#231F20",
                }}
              >
                Join as Buisness
              </Button>
            </div>
          </div>
          <div
            className="col-lg-4 px-4 col-md-6 col-12 "
            style={{ height: "550px" }}
          >
            <div className="hw-webi-work my-4 d-flex flex-column justify-content-center align-items-center h-100">
              <div
                id="prog"
                className="rounded-circle hw-work-1 mb-2 d-flex justify-content-center align-items-center"
              >
                <img src={driver} alt="" height="50%" width="50%" />
              </div>
              <p className="  mb-2 text-center" style={{ fontSize: "48px" }}>
                Drivers
              </p>
              <p
                className="  mb-2 text-center px-4"
                style={{ fontSize: "24px", color: "#666666" }}
              >
                Drive the North American economy with us and enjoy a bundle of
                health and financial benefits.
              </p>
              <Button
                className="px-4"
                style={{
                  borderRadius: "40px",
                  border: "none",
                  boxShadow: "1px 2px #707070",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  backgroundColor: "#FADE31",
                  color: "#231F20",
                }}
              >
                Join as Rider
              </Button>
            </div>
          </div>
          <div
            className="col-lg-4 px-4 col-md-6 col-12 "
            style={{ height: "550px" }}
          >
            <div className="hw-webi-work my-4 d-flex flex-column justify-content-center align-items-center h-100">
              <div
                id="prog"
                className="rounded-circle hw-work-1 mb-2 d-flex justify-content-center align-items-center"
              >
                <img src={user} alt="" height="50%" width="50%" />
              </div>
              <p className="  mb-2 text-center" style={{ fontSize: "48px" }}>
                Beezhiver
              </p>
              <p
                className="  mb-2 text-center px-4"
                style={{ fontSize: "24px", color: "#666666" }}
              >
                Join North America’s only hyper-growth potential billion dollars
                startup And create impact.
              </p>
              <Button
                className="px-4"
                style={{
                  borderRadius: "40px",
                  border: "none",
                  boxShadow: "1px 2px #707070",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  backgroundColor: "#FADE31",
                  color: "#231F20",
                }}
              >
                Join as employee
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section style={{ height: "100vh", overflow: "hidden" }}>
        <div className="row gx-0">
          <div className="col-md-8">
            <div
              style={{
                marginLeft: "8%",
                color: "#666666",

                fontWeight: "700",
                fontSize: "110px",
                lineHeight: "6rem",
              }}
            >
              Download <br /> the Beezhive app today!
            </div>
            <div
              className="col-md-10 mt-4 mx-auto"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                style={{
                  padding: "20px 25px",
                  borderRadius: "42px",
                  border: "none",
                  boxShadow: "1px 2px #707070",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  backgroundColor: "#FADE31",
                  color: "#231F20",
                  fontSize: "28px",
                }}
              >
                <img
                  className="my-auto"
                  src={playstore}
                  style={{ height: "30px", width: "30px" }}
                ></img>{" "}
                Play store
              </Button>
              <Button
                className=" my-auto"
                style={{
                  padding: "20px 25px",
                  border: "none",
                  boxShadow: "1px 2px #707070",
                  borderRadius: "42px",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  backgroundColor: "#FADE31",
                  color: "#231F20",
                  fontSize: "28px",
                }}
              >
                <img
                  className=""
                  src={apple}
                  style={{
                    height: "35px",
                    width: "30px",
                    paddingBottom: "2%",
                  }}
                ></img>{" "}
                Apple store
              </Button>
            </div>
            <div
              className="rotate-mobile"
              style={{ paddingTop: "3rem", overflow: "hidden" }}
            >
              <img src={mobilescreen2} height="600px" width="350px"></img>
            </div>
          </div>

          <div className="col-md-auto" style={{ paddingTop: "3rem" }}>
            <img src={mobilescreen} height="600px" width="350px"></img>
          </div>
        </div>
      </section>
      <section
        id="footer"
        className="footer"
        style={{
          backgroundColor: "white",

          paddingTop: "5rem",
          paddingBottom: "2rem",

          borderTopLeftRadius: "5%",
          borderTopRightRadius: "5%",
        }}
      >
        <div
          className="row gx-0"
          style={{
            display: "flex",
            paddingLeft: "5%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="" style={{ width: "fit-content" }}>
            <div>
              <img
                src={brandlogo}
                style={{ height: "80px", width: "300px" }}
              />
            </div>
            <span
              style={{
                color: "#C2C2C2",
                fontWeight: "400",
                fontSize: "32px",
              }}
            >
              Download the Beezhive App.
            </span>
            <div
              className="my-auto p-0 mt-4 "
              // style={{ display: "flex", justifyContent: "space-around" }}
            >
              <ReactStoreBadges platform={"ios"} url="" locale={"en-us"} />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <ReactStoreBadges platform={"android"} url="" locale={"en-us"} />
            </div>
          </div>
          <div
            className="mx-4"
            style={{ width: "fit-content", textAlign: "center" }}
          >
            <span
              style={{
                color: "#555758",
                fontWeight: "600",
                fontSize: "30px",
              }}
            >
              Connect with Us
            </span>
            <div className="my-4">
              <a href="https://twitter.com/beezhivecanada" target="_blank">
                <img
                  className="mx-4"
                  src={twitter}
                  style={{ height: "40px", width: "40px" }}
                />
              </a>
              <a href="https://facebook.com/beezhivecanada" target="_blank">
                <img
                  className="mx-4"
                  src={fb}
                  style={{ height: "40px", width: "40px" }}
                />
              </a>
              <a href="https://instagram.com/beezhive.ca" target="_blank">
                <img
                  className="mx-4"
                  src={insta}
                  style={{ height: "40px", width: "40px" }}
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCkYMPhOJnyyDgV6Lo_srVrw"
                target="_blank"
              >
                <img
                  className="mx-4"
                  src={youtube}
                  style={{ height: "40px", width: "40px" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="row col-md-11 mx-auto gx-0 mt-4 py-2">
          <div
            className="col-lg-3 col-md-3 col-8 mb-2 "
            style={{ paddingLeft: "3%" }}
          >
            <p
              style={{
                color: "#555758",
                fontWeight: "600",
                fontSize: "30px",
              }}
            >
              Company
            </p>
            <div>
              <a
                href="#about-us"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p
                  style={{
                    color: "#C2C2C2",
                    fontWeight: "100",
                    fontSize: "30px",
                  }}
                  className="my-0"
                >
                  About
                </p>
              </a>
              <a
                href="https://beezhive.medium.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p
                  style={{
                    color: "#C2C2C2",
                    fontWeight: "400",
                    fontSize: "32px",
                  }}
                  className="my-0"
                >
                  Blog
                </p>
              </a>

              <p
                style={{
                  color: "#C2C2C2",
                  fontWeight: "400",
                  fontSize: "30px",
                }}
                className="my-0"
              >
                Services
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3 col-8 mb-2"
            style={{ paddingLeft: "3%" }}
          >
            <p
              style={{
                color: "#555758",
                fontWeight: "600",
                fontSize: "30px",
              }}
            >
              Partner with Us
            </p>
            <div>
              <p
                style={{
                  color: "#C2C2C2",
                  fontWeight: "400",
                  fontSize: "30px",
                }}
                className="my-0"
              >
                Driver Partner
              </p>
              <p
                style={{
                  color: "#C2C2C2",
                  fontWeight: "400",
                  fontSize: "32px",
                }}
                className="my-0"
              >
                Merchant Partner
              </p>
              <p
                style={{
                  color: "#C2C2C2",
                  fontWeight: "100",
                  fontSize: "30px",
                }}
                className="my-0"
              >
                Beezpay API
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3 col-8 mb-2"
            style={{ paddingLeft: "3%" }}
          >
            <p
              style={{
                color: "#555758",
                fontWeight: "600",
                fontSize: "30px",
              }}
            >
              Careers
            </p>
            <div>
              <a
                href="https://beezhive.zohorecruit.in/jobs/Careers"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p
                  style={{
                    color: "#C2C2C2",
                    fontWeight: "400",
                    fontSize: "30px",
                  }}
                  className="my-0"
                >
                  Jobs
                </p>
              </a>
              <a
                href="https://beezhive.zohorecruit.in/jobs/Careers"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p
                  style={{
                    color: "#C2C2C2",
                    fontWeight: "100",
                    fontSize: "30px",
                  }}
                  className="my-0"
                >
                  Internship
                </p>
              </a>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3 col-8 mb-2"
            style={{ paddingLeft: "3%" }}
          >
            <p
              style={{
                color: "#555758",
                fontWeight: "700",
                fontSize: "30px",
              }}
            >
              Get in touch
            </p>
            <div>
              <p
                style={{
                  color: "#C2C2C2",
                  fontWeight: "200",
                  fontSize: "30px",
                }}
                className="my-0"
              >
                Help Center
              </p>
              <p
                style={{
                  color: "#C2C2C2",
                  fontWeight: "100",
                  fontSize: "30px",
                }}
                className="my-0"
              >
                Contact us
              </p>
            </div>
          </div>
        </div>
        <div
          className="row mt-4 mx-auto "
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="px-4" style={{ width: "fit-content" }}>
            <a href="/privacy" style={{ textDecoration: "none" }}>
              <span
                style={{
                  color: "#C2C2C2",
                  fontWeight: "300",
                  fontSize: "32px",
                }}
              >
                Privacy Policy
              </span>
            </a>
          </div>

          <div className="px-4" style={{ width: "fit-content" }}>
            <a href="/terms" style={{ textDecoration: "none" }}>
              <span
                style={{
                  color: "#C2C2C2",
                  fontWeight: "300",
                  fontSize: "32px",
                }}
              >
                Terms & Condition
              </span>
            </a>
          </div>
        </div>
        <div className="mt-4 " style={{ textAlign: "center" }}>
          <p
            style={{
              color: "#C2C2C2",
              fontWeight: "400",
              fontSize: "32px",
            }}
          >
            Made with <span style={{ color: "red" }}>❤</span> for{" "}
            <span>
              <img src={canadaflag} />
            </span>{" "}
          </p>
        </div>
      </section>
    </>
  );
};
export default IndexPage;
